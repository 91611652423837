<template>
  <section>
    <!--工具条-->
    <toolbar :buttonList="buttonList"
             @callFunction="callFunction"
             :buttonListmsg="buttonListmsg"></toolbar>
    <el-table :data="users"
              ref="multipleTable"
              highlight-current-row
              row-key="Id"
              lazy
              :indent="30"
              :default-expand-all="false"
              :load="load"
              :tree-props="{children: 'children',hasChildren: 'IsHasChildren'}"
              :fit="true"
              @current-change="selectCurrentRow"
              @row-dblclick="checkInfo"
              :cell-style="mycellStyle"
              :header-cell-style="{background:'#dce4f1',color:'#000000'}"
              @cell-mouse-enter="tabmouseEnter"
              @cell-mouse-leave="tabmouseLeave"
              style="width: 100%">
      <!-- <el-table-column type="index"></el-table-column> -->
      <el-table-column type="index" width="6">
        <template scope="scope">
        </template>
      </el-table-column>
      <el-table-column label="工作项目"
                       prop="WorkItem"
                       min-width="460px">
        <template slot-scope="scope">
          <!-- <i class="el-icon-timer"
             v-if="scope.row.ParentId == null && scope.row.IsHasChildren == true"></i>
          <i class="el-icon-xxxxxxxx"
             v-if="scope.row.ParentId != null"></i> -->
             <span :style="{'cursor':'default','display':'block','padding-left':'20px','margin-top':scope.row.IsHasChildren?'-22px':'0px','margin-bottom':scope.row.IsHasChildren ?'0px':'15px','margin-left': scope.row.MarginLeft+'px' }">{{scope.row.Number}}、{{scope.row.WorkItem}}
               <el-button @click="handleCollect(scope.row)" style="color:#FF9800;margin-right:3px;" size="mini" v-show="(collectBtn&&collectRowId==scope.row.Id) || scope.row.CollectId!=null" :title="scope.row.CollectId==null?'特别关注':'已特别关注'" type="default" :icon="scope.row.CollectId==null?'el-icon-star-off':'el-icon-star-on'" circle></el-button>
             </span>
             
             <!-- <span :style="{'cursor':'default'}">{{scope.row.WorkItem}}</span> -->
            <!-- <span v-if="!scope.row.WorkDescription" ><el-button @click="handleCollect(scope.row)" style="margin-right:3px;color:#FF9800;" size="mini" v-show="(collectBtn&&collectRowId==scope.row.Id) || scope.row.CollectId!=null"  :title="scope.row.CollectId==null?'特别关注':'已特别关注'"  type="default" :icon="scope.row.CollectId==null?'el-icon-star-off':'el-icon-star-on'" circle></el-button>{{scope.row.WorkItem}}</span>
            <el-button v-else style="color:#606266;font-weight:normal;cursor:default" type="text"><el-button @click="handleCollect(scope.row)" style="margin-right:3px;color:#FF9800;" size="mini" v-show="(collectBtn&&collectRowId==scope.row.Id) || scope.row.CollectId!=null"  :title="scope.row.CollectId==null?'特别关注':'已特别关注'"  type="default" :icon="scope.row.CollectId==null?'el-icon-star-off':'el-icon-star-on'" circle></el-button>{{scope.row.WorkItem}}</el-button> -->
            <!-- <el-tooltip v-else placement="right-start" effect="light" :enterable="true">
              <div slot="content" v-html="'<span style=\'color:#999\' >工作项目:</span><br/>'+scope.row.WorkItem+ '<br/><span style=\'color:#999\' >工作描述:</span><br/>'+scope.row.WorkDescription.replace(/\n/g,'<br/>')+ '<br/><span style=\'color:#999\' >创建于:</span><br/>'+scope.row.CreateTime+ '<br/><span style=\'color:#999\' >计划完成时间:</span><br/>'+scope.row.PlanComplateTime+ '<br/><span style=\'color:#999\' >进度:</span><br/>'+formatProgress(scope.row)+ '<br/><span style=\'color:#999\' >最新进度:</span><br/>'+(scope.row.LastProgressDescription==null?'':scope.row.LastProgressDescription.replace(/\n/g,'<br/>'))" ></div>
              <el-button style="color:#606266;font-weight:normal;cursor:default" type="text"><el-button @click="handleCollect(scope.row)" style="margin-right:3px;color:#FF9800;" size="mini" v-show="(collectBtn&&collectRowId==scope.row.Id) || scope.row.CollectId!=null"  :title="scope.row.CollectId==null?'特别关注':'已特别关注'"  type="default" :icon="scope.row.CollectId==null?'el-icon-star-off':'el-icon-star-on'" circle></el-button>{{scope.row.WorkItem}}</el-button>
            </el-tooltip> -->

        </template>
      </el-table-column>
      <!-- <el-table-column label="具体描述及要求" prop="WorkDescription"></el-table-column> -->
      <el-table-column label="计划完成时间"
                       prop="PlanComplateTime"
                       :formatter="formatPlanComplateTime"
                       min-width="150px">
        <template slot-scope="scope">
          <div v-if="new Date()>new Date(new Date(new Date(scope.row.PlanComplateTime).toLocaleDateString()).getTime()+24*60*60*1000-1)&&scope.row.TaskProgressValue<100">
            <font color=red>{{formatPlanComplateTime(scope.row)}}</font>
            &nbsp;<el-tag v-if="scope.row.IsProlongTime" type="success" size="mini">延</el-tag>
          </div>
          <div v-else>
            <font color=black>{{formatPlanComplateTime(scope.row)}}</font>
            &nbsp;<el-tag v-if="scope.row.IsProlongTime" type="success" size="mini">延</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="责任人"
                       prop="PersonOfDuty"
                       min-width="80px"></el-table-column>
      <el-table-column label="任务状态"
                       prop="Status"
                       align="center"
                       min-width="100px">
        <template slot-scope="scope">
          <div :style="{'color':scope.row.TodayChangeProgress?'green':'#606266'}" v-if="scope.row.TaskProgressValue<100" style="font-size:10px;">进度:{{formatProgress(scope.row)}}</div>
          <el-tag v-if="scope.row.Status == 0"
                  effect="dark"> 进行中</el-tag>
          <el-tag v-if="scope.row.Status == 1"
                  effect="dark"
                  type="warning">已完成审核中</el-tag>
          <el-tag v-if="scope.row.Status == 2"
                  effect="dark"
                  type="success">已完成</el-tag>
          <el-tag v-if="scope.row.Status == -1"
                  effect="dark"
                  type="info">已取消</el-tag>
          <el-tag v-if="scope.row.Status == 3"
                  effect="dark"
                  type="danger">退回</el-tag>
          <el-tag v-if="scope.row.Status == 4"
                  effect="dark"
                  type="warning">新建任务待审核</el-tag>
          <el-tag v-if="scope.row.Status == 5"
                  effect="dark"
                  type="danger">取消任务待审核</el-tag>
          <el-tag v-if="scope.row.Status == 6"
                  effect="dark"
                  type="danger">新建任务退回</el-tag>
        </template>
      </el-table-column>
      <!-- <el-table-column label="进度"
                       prop="TaskProgressValue"
                       :formatter="formatProgress"
                       align="center"
                       min-width="60px">
                       
        <template slot-scope="scope">
          <font v-if="scope.row.TodayChangeProgress" style="color:green" >{{formatProgress(scope.row,null)}}</font>
          <font v-else-if="!scope.row.TodayChangeProgress" >{{formatProgress(scope.row,null)}}</font>
        </template>
      </el-table-column> -->
      <el-table-column label="任务类型"
                       prop="CommonTaskClassifyText"
                       :formatter="formatCommonTaskClassifyText"
                       align="center"
                       min-width="100px"></el-table-column>
      <!-- <el-table-column label="任务来源"
                       prop="TaskStatus"
                       align="center"
                       min-width="100px"></el-table-column> -->

      <el-table-column label="任务来源" prop="TaskStatus" min-width="90px">
        <template slot-scope="scope">
          <div style="font-size:10px">{{(scope.row.TaskStatus)}}</div>
          <div v-if="scope.row.AssignName" style="font-size:10px">由{{scope.row.AssignName}}指派</div>
        </template>
      </el-table-column>
      <el-table-column label="创建时间"
                       prop="CreateTime"
                       :formatter="formatCreateTime"
                       align="center"
                       min-width="80px">
        <template slot-scope="scope">
          <el-tooltip placement="left" effect="light">
            <div slot="content">
              {{ formatTipCreateTime( scope.row) }}
            </div>
            <div>
              {{ formatCreateTime( scope.row) }}
            </div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="完成后审核"
                       prop="IsNeedApproval"
                       align="center"
                       min-width="70px">
        <template slot-scope="scope">
          <el-tag :type="scope.row.IsNeedApproval ? 'warning' : 'success'"
                  disable-transitions>
            {{scope.row.IsNeedApproval ? "是" : "否"}}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作"
                       align="center"
                       min-width="90px">
        <template slot-scope="scope">
            <el-row v-if="scope.row.ParentId == null">
              <el-link type="primary" :underline="false" @click="checkInfo(scope.row)">维护</el-link>
              <el-button type="text"
                         v-if="scope.row.Status == 3 || scope.row.Status == 6"
                         style="color: red;"
                         @click="readdSubmit(scope.row)">重新提交</el-button>
            </el-row>
            <el-row v-else>
              <el-link type="primary" :underline="false" @click="checkInfo(scope.row)">查看</el-link>
              <el-button type="text"
                         v-if="scope.row.Status == 3 || scope.row.Status == 6"
                         style="color: red;"
                         @click="readdSubmit(scope.row)">重新提交</el-button>
            </el-row>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-pagination align='center'
                   @current-change="handleCurrentChange"
                   :current-page="currentPage"
                   :page-size="pageSize"
                   layout="prev, pager, next, jumper"
                   :page-count="total"
                   :total="totaldata">
    </el-pagination>
    <!--工作任务添加-->
    <el-dialog :visible.sync="addFormVisible"
               v-model="addFormVisible"
               @close="clickclose"
               :close-on-press-escape="false"
               :close-on-click-modal="false"
               width="55%"
               append-to-body
               @opened="handleAddFormOpened"
               center>
      <template slot="title">
        <span style="color:#FFFFFF;font-size: 16px;">新增日常任务</span>
      </template>
      <el-form :model="addForm"
               label-width="85px"
               :rules="addFormRules"
               ref="addForm">
        <el-row>
          <el-col :span="14">
            <el-form-item label="任务类型:"
                          prop="CommonTaskClassify">
              <el-radio-group v-model="addForm.CommonTaskClassify" >
                <el-radio @change="handleTaskClassifyChange" v-for="item in CommonTaskClassifyList" v-bind:key="item.Id" :label="item.Id">{{item.Name}}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="10"
                  v-show="showYearPlanTask">
            <el-form-item label="年度计划:"
                          prop="YearPlanTask">
              <el-select style="width:100%" :disabled="selectYearplanDisabled" v-model="addForm.YearPlanTask"
                         @change="YearPlanchange"
                         clearable>
                <el-option v-for="item in YearPlanTaskList"
                           :key="item.Id"
                           :value="`${item.YearPlanId}|${item.TaskId}|${item.WorkItem}`"
                           :label="item.WorkItem">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-row v-show="showleader">
              <el-row>
                <el-col :span="17">
                  <el-form-item label="指令人:"
                                prop="InstructUserName">
                    <el-input v-model="addForm.InstructUserName"
                              readonly
                              placeholder="请选择指令人">{{orderneedname}}</el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="2">
                  <el-button type="text">
                    <userchoosetool @ordercallFunction="ordercallFunction"
                                    :callbackmsg="orderpeo"></userchoosetool>
                  </el-button>
                </el-col>
              </el-row>
            </el-row>
          </el-col>
        </el-row>
        <el-form-item label="工作项目:"
                      prop="WorkItem">
          <el-input v-model.trim="addForm.WorkItem"
                    maxlength="50"
                    show-word-limit
                    placeholder="用一句话概述工作项目"
                    :readonly="WorkItemreadonly"></el-input>
        </el-form-item>
        <el-form-item label="工作描述:" prop="WorkDescription">
          <span :style="{'font-size':'9px','margin-right':'3px','font-weight':descModeVal?'normal':'bold'}">简单文本</span>
           <el-switch style="margin-left:0px;z-index:99;"
              v-model="descModeVal" @change="handleChangeDescMode"
              inactive-color="#13ce66"
              active-color="#ff4949"
              >
            </el-switch>
            <span :style="{'font-size':'9px','margin-left':'3px','font-weight':!descModeVal?'normal':'bold'}">富文本</span>
          <editordesc v-show="descModeVal" ref="editorDescElem" style="width: 100%" :catchData="catchDataDesc" />
          <el-input v-show="!descModeVal" @change="handleChangeWorkdesc" v-model="simpleWorkDescription"
                    type="textarea"
                    placeholder="请描述工作内容"
                    :autosize="{ minRows: 4, maxRows: 7}"
                    maxlength="500"
                    show-word-limit></el-input>
        </el-form-item>
        <!-- <el-form-item prop="ckbreview">
          <span slot="label" style="white-space:nowrap">参与审核评分:</span>
          <el-switch v-model="ckbreview" @change="handleReviewChange"
                    style="margin-left:15px"></el-switch>
                    <span v-if="false">参与审核评分</span>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <el-tooltip class="item" effect="dark" content="若参与审核评分，当此任务完成后由部门负责人评分。" placement="top-start">
                        <el-link style="margin-top:-2px;" type="primary">？</el-link>
              </el-tooltip>
        </el-form-item> -->
        <div v-show="iscomplated" style="height:35px">
          <span style="margin-left:15px;">是否已完成:</span>
          <el-switch v-model="addForm.DefaultComplated" @change="handleDefaultComplatedSwitchChange"
                    style="margin-left:15px"></el-switch><span style="margin-left:2px;font-size:9px;" v-if="addForm.DefaultComplated == true">此任务已完成</span>
        </div>
        <!-- <div v-show="yearplandiv"> -->
        <div v-show="false">
          <vxe-table border="full"
                     style="margin-top:15px"
                     auto-resize
                     :data="yearplandata"
                     :edit-config="{trigger: 'click', mode: 'cell'}">
            <vxe-table-column type="seq"
                              width="60"
                              title="序号"></vxe-table-column>
            <vxe-table-column field="FieldName"
                              title="任务属性名称"
                              width="150"></vxe-table-column>
            <!-- <vxe-table-column field="FieldType" title="输入类型" width="80" :formatter="FieldTypeFormat" ></vxe-table-column> -->
            <vxe-table-column field="showTip"
                              title=""
                              width="5">
              <template scope="scope">
                <span v-if="scope.row.FieldMustInput==1"
                      style="color:red">*</span>
              </template>
            </vxe-table-column>
            <vxe-table-column field="FieldValue"
                              title="值(此列可点击填写)"
                              width="250"
                              scope="scope"
                              :edit-render="{name: 'input'}">

              <template v-slot:edit="{ row }">
                <vxe-input v-if="row.FieldType == 1 || row.FieldType == 2"
                           :type="formatTypeOld(row)"
                           :maxlength="inputMaxLength"
                           min=0
                           :max="MaxLength"
                           :oninput="controlInputLength(row)"
                           v-model="row.FieldValue"
                           :placeholder="inputpla"></vxe-input>
              </template>
            </vxe-table-column>
            <vxe-table-column field="FieldUnit"
                              title="属性单位"
                              width="80"></vxe-table-column>
            <vxe-table-column field="FieldMustInput"
                              title="是否必填"
                              width="80"
                              :formatter="FieldMustInputFormat"></vxe-table-column>
          </vxe-table>

        </div>
        <el-row >
          <el-col :span="8">
            <el-form-item label="计划完成时间:" label-width="120px" prop="PlanComplateTime">
              <el-date-picker :picker-options="pickerOptions0"
                              v-model="addForm.PlanComplateTime"
                              type="date"
                              value-format="yyyy-MM-dd"
                              placeholder="请选择日期"
                              style="width:140px; left: 00px;"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-row>
              <el-col :span="24">
                <el-form-item label="责任人:"
                              prop="PersonOfDuty">

                  <el-input :disabled="selectPeopleDisabled" v-model="dutyneedname" style="width:70%" readonly placeholder="责任人"></el-input>
                  <!-- <el-select @change="handlePersonChange" :disabled="selectPeopleDisabled" v-model="addForm.PersonOfDuty" placeholder="选择责任人">
                    <el-option
                      v-for="item in PersonOfDutyArr"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select> -->
                    <el-button v-show="!selectPeopleDisabled" type="text">
                      <userchoosetool style="margin-top:-6px"
                                      @dutycallFunction="dutycallFunction"
                                      :callbackmsg="dutypeo" :mytext="mytext"></userchoosetool>
                    </el-button>
                  
                  <el-tooltip content="选择他人可将任务创建至他的任务列表中" placement="right" effect="light">
                    <i v-show="!selectPeopleDisabled" style="margin-left:0px" class="el-icon-warning-outline" ></i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="5">
            <el-form-item prop="ckbreview">
              <span slot="label" style="white-space:nowrap">参与审核评分:</span>
              <el-switch v-model="ckbreview" @change="handleReviewChange"
                        style="margin-left:5px"></el-switch>
                        <span v-if="false">参与审核评分</span>
                  <el-tooltip class="item" effect="dark" content="若参与审核评分，当此任务完成后由部门负责人评分。" placement="top-start">
                            <el-link style="margin-top:-2px;" type="primary">？</el-link>
                  </el-tooltip>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-form-item label="进度维护量化指标库：" v-show="visiableZhibiao"
                      prop="TemplateId">
          <el-select v-model="addForm.TemplateId" filterable placeholder="可在量化指标库中选择" style="margin-left:60px;width:220px;" :clearable="true" @change="handleZhibiaoSelectChange">
            <el-option-group
              v-for="group in zhibiaoOptions"
              :key="group.label"
              :label="group.label">
              <el-option
                v-for="item in group.options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-option-group>
          </el-select>
          <el-button type="text" style="margin-left:5px;" @click="handleZhibiao">浏览指标库</el-button>
          <el-popover
            placement="bottom"
            width="600"
            trigger="click">
            <el-table :data="lookdetail">
              <el-table-column property="FieldName" min-width="100px" label="指标项"></el-table-column>
              <el-table-column property="FieldUnit" align="center" label="值单位"></el-table-column>
              <el-table-column property="FieldType" align="center" label="值类型">
                <template scope="scope">
                  <span>{{formatType(scope.row.FieldType)}}</span>
                </template>
              </el-table-column>
              <el-table-column property="FieldLength" align="center" label="值长度"></el-table-column>
              <el-table-column property="FieldMustInput" align="center" label="是否必填">
                <template scope="scope">
                  <span>{{scope.row.FieldMustInput?'是':'否'}}</span>
                </template>
              </el-table-column>
            </el-table>
            <el-button style="margin-left:5px;" slot="reference" @click="handleLookTemplateDetail(addForm.TemplateId)" type="text">选中项预览</el-button>
          </el-popover>
          <el-button style="margin-left:5px;" @click="handleAddZhibiaoConfig()" type="text">新建</el-button>
        </el-form-item> -->
        <el-form-item label="量化指标：" v-show="visiableZhibiao"
                      prop="TemplateId">


          <el-input :placeholder="choosetip" style="cursor:pointer;width:85%;" :readonly="true" v-model="addForm.TemplateName" class="input-with-select" @click.native="handleChooseZhibiao">
            <el-select v-model="zhibiaosource" slot="prepend" placeholder="请选择" @change="handleChangeZhibiaoSource" style="width:140px;">
              <el-option label="从指标库选择" :value="1"></el-option>
              <el-option label="临时新建" :value="2"></el-option>
            </el-select>
            <!-- <el-button slot="append" icon="el-icon-search">预览</el-button> -->

          </el-input>
          <el-popover
            placement="bottom"
            width="600"
            trigger="click">
            <el-table :data="lookdetail">
              <el-table-column property="FieldName" min-width="100px" label="指标项"></el-table-column>
              <el-table-column property="FieldUnit" align="center" label="值单位"></el-table-column>
              <el-table-column property="FieldType" align="center" label="值类型">
                <template scope="scope">
                  <span>{{formatType(scope.row.FieldType)}}</span>
                </template>
              </el-table-column>
              <el-table-column property="FieldLength" align="center" label="值长度"></el-table-column>
              <el-table-column property="FieldMustInput" align="center" label="是否必填">
                <template scope="scope">
                  <span>{{scope.row.FieldMustInput?'是':'否'}}</span>
                </template>
              </el-table-column>
            </el-table>
            <el-button style="margin-left:5px;" slot="reference" @click="handleLookTemplateDetail(addForm.TemplateId)" type="text">预览</el-button>
          </el-popover>




          <!-- <el-select v-model="addForm.TemplateId" filterable placeholder="可在量化指标库中选择" style="margin-left:60px;width:220px;" :clearable="true" @change="handleZhibiaoSelectChange">
            <el-option-group
              v-for="group in zhibiaoOptions"
              :key="group.label"
              :label="group.label">
              <el-option
                v-for="item in group.options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-option-group>
          </el-select>
          <el-button type="text" style="margin-left:5px;" @click="handleZhibiao">浏览指标库</el-button>
          <el-popover
            placement="bottom"
            width="600"
            trigger="click">
            <el-table :data="lookdetail">
              <el-table-column property="FieldName" min-width="100px" label="指标项"></el-table-column>
              <el-table-column property="FieldUnit" align="center" label="值单位"></el-table-column>
              <el-table-column property="FieldType" align="center" label="值类型">
                <template scope="scope">
                  <span>{{formatType(scope.row.FieldType)}}</span>
                </template>
              </el-table-column>
              <el-table-column property="FieldLength" align="center" label="值长度"></el-table-column>
              <el-table-column property="FieldMustInput" align="center" label="是否必填">
                <template scope="scope">
                  <span>{{scope.row.FieldMustInput?'是':'否'}}</span>
                </template>
              </el-table-column>
            </el-table>
            <el-button style="margin-left:5px;" slot="reference" @click="handleLookTemplateDetail(addForm.TemplateId)" type="text">选中项预览</el-button>
          </el-popover>
          <el-button style="margin-left:5px;" @click="handleAddZhibiaoConfig()" type="text">新建</el-button> -->
        </el-form-item>
        <el-form-item label="备注:"
                      prop="Remark">
          <el-input v-model="addForm.Remark"
                    maxlength="50"
                    show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="是否指派:"
                      prop="IsAssign"
                      v-if="false">
          <el-switch v-model="addForm.IsAssign"
                     @change="clickAssign"></el-switch>
        </el-form-item>
        <el-form-item label="附件:"
                      prop="Remark">
          <upload-files :files="fileList"
                        :key="key"
                        action="https://api.gradgroup.cn/ftp/ftp/upload"
                        :limit="5"
                        @fun="dealFiles"
                        @delfun="deleteFiles"
                        :multiple="false"
                        :IsDisabled="operation1"
                        :IsDel="operation1"></upload-files>
        </el-form-item>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button type="goon"
                   @click.native="addSubmit"
                   v-show="showSubmit">提交</el-button>
        <el-button type="goon"
                   @click="nextStep"
                   v-show="showNext">下一步</el-button>
      </div>
    </el-dialog>
    <!--工作任务添加并指派-->
    <el-dialog :visible.sync="addAssignFormVisible"
               v-model="addAssignFormVisible"
               @close="closeassign"
               :close-on-press-escape="false"
               :close-on-click-modal="false"
               @opened="assignTaskOpened"
               @closed="assignTaskClosed"
               center>
      <template slot="title">
        <span style="color:#FFFFFF;font-size: 16px;">指派任务</span>
      </template>
      <el-form label-width="85px"
               :rules="addFormRules"
               style="white-space:nowrap;">
        <el-row>
          <el-col :span="0.1"
                  style="margin-top:10px">
            <span style="color: red;">*</span>
          </el-col>
          <el-col :span="10">
            <el-form-item label="被指派人:"
                          prop="AssignName">
              <el-input v-model="addForm.AssignName"
                        readonly
                        placeholder="请选择被指派人">{{needName}}</el-input>
              <el-button type="text"
                         size="mini"
                         @click="handleChooseAssUser">选择人员</el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="0.1"
                  style="margin-top:10px">
            <!-- <span style="color: red;">*</span> -->
            <span style="margin-left:20px"></span>
          </el-col>
          <el-col :span="4">
            <el-form-item label="完成后需审核:"
                          prop="IsNeedApproval">
              <el-switch v-model="addForm.IsNeedApproval"
                         style="left: 10px;" @change="needapprovalChange"></el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="4" v-show="addForm.IsNeedApproval">
            <el-input v-model="addForm.ApprovalUserName" style="width:88px;margin-left:15px;"
              readonly placeholder="审核人">{{approvalName}}</el-input>
            <el-button type="text">
              <userchoosetool style="margin-top:-6px"
                              @approvalcallFunction="approvalcallFunction"
                              :callbackmsg="shenherenyuan"></userchoosetool>
            </el-button>
            <el-button type="text" @click="chooseMe" >我</el-button>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="0.1"
                  style="margin-top:10px">
            <span style="color: red;">*</span>
          </el-col>
          <el-col :span="23">
            <el-form-item label="工作项目:"
                          prop="WorkItem2">
              <el-input v-model="addForm.WorkItem2"
                        maxlength="30"
                        show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="0.1"
                  style="margin-top:10px">
            <span style="color: red;">*</span>
          </el-col>
          <el-col :span="23">
            <el-form-item label="工作描述:"
                          prop="WorkDescription2">

              <editordesc v-show="assignDescModeVal" ref="editorAssignDescElem" style="width: 100%" :catchData="catchAssignDataDesc" />
              <el-input v-show="!assignDescModeVal" v-model="addForm.WorkDescription2"
                        type="textarea"
                        :autosize="{ minRows: 4, maxRows: 7}"
                        maxlength="500"
                        show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="height: 40px;">
          <el-col :span="0.1"
                  style="margin-top:5px">
            <span style="color: red;">*</span>
          </el-col>
          <el-col :span="2.5" style="margin-top:5px;">
            <span>计划完成时间:</span>
          </el-col>
          <el-col :span="7" style="margin-left:5px">
            <el-date-picker :picker-options="pickerOptions0"
                            v-model="addForm.PlanComplateTime2"
                            type="date"
                            value-format="yyyy-MM-dd"
                            placeholder="请选择日期"></el-date-picker>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button type="goon"
                   @click.native="AssignTask">提交</el-button>
      </div>
    </el-dialog>
    <!--编辑任务界面-->
    <el-dialog :visible.sync="editFormVisible"
               v-model="editFormVisible"
               :close-on-press-escape="false"
               :close-on-click-modal="false"
               @opened="editTaskOpened"
               @closed="editTaskClosed"
               center>
      <template slot="title">
        <span style="color:#FFFFFF;font-size: 16px;">编辑任务</span>
      </template>
      <el-form :model="editForm"
               label-width="85px"
               :rules="editFormRules"
               ref="editForm">
        <el-form-item label="任务类型:"
                      prop="CommonTaskClassify">
          <el-select v-model="editForm.CommonTaskClassify"
                     placeholder="请选择任务类型"
                     disabled>
            <el-option v-for="item in CommonTaskClassifyList"
                       :key="item.Id"
                       :value="item.Id"
                       :label="item.Name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="工作项目:"
                      prop="WorkItem">
          <el-input v-model="editForm.WorkItem"
                    maxlength="30"
                    show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="工作描述:"
                      prop="WorkDescription">

          <editordesc v-show="editDescModeVal" ref="editorEditDescElem" style="width: 100%" :catchData="catchEditDataDesc" />
          <el-input v-show="!editDescModeVal" v-model="editForm.WorkDescription"
                    type="textarea"
                    :autosize="{ minRows: 4, maxRows: 7}"
                    maxlength="500"
                    show-word-limit></el-input>
        </el-form-item>
        <el-row style="height: 40px; margin-bottom: 20px;">
          <el-col :span="0.1">
            <span style="color: red;">*</span>
          </el-col>
          <el-col :span="2.5">
            <span>计划完成时间:</span>
          </el-col>
          <el-col :span="7">
            <el-date-picker :picker-options="pickerOptions0"
                            v-model="editForm.PlanComplateTime"
                            type="date"
                            value-format="yyyy-MM-dd"
                            placeholder="请选择日期"></el-date-picker>
          </el-col>
        </el-row>
        <el-form-item label="备注:"
                      prop="Remark">
          <el-input v-model="editForm.Remark"
                    maxlength="50"
                    show-word-limit></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button type="goon"
                   @click.native="editSubmit">提交</el-button>
      </div>
    </el-dialog>
    
    <taskinfo v-if="checkVisible" :data="checkForm" @closeFunction="checkclose" type="normal" ></taskinfo>

    <!--新增工作进度界面-->
    <el-dialog :visible.sync="addProgressVisible"
               v-model="addProgressVisible"
               :close-on-press-escape="false"
               :close-on-click-modal="false"
               @close="proclose"
               @opened="addopen"
               style="width: 80%; left: 15%;"
               center>
      <template slot="title">
        <span style="color:#FFFFFF;font-size: 16px;">新增工作进度</span>
      </template>
      <el-form :model="addProgressForm"
               :rules="addProgressFormRules">
        <el-form-item label="进度描述:"
                      prop="ProgressDescription">
          <el-input v-model="addProgressForm.ProgressDescription"
                    rows="5"
                    placeholder="请尽可能的描述任务推进过程、量化数据和成果"
                    maxlength="700"
                    show-word-limit
                    type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="具体进度:">
          <el-row>
            <el-col :span="11">
              <el-slider v-model="pValue2"
                         @input="handleProcessInput"
                         :step="5"></el-slider>
            </el-col>
            <el-col :span="2.2">
              <div style="margin-left:9px">{{pValue2}}%</div>
            </el-col>
            <el-col :span="7">
              <div style="margin-left:3px;font-size:12px">
                <i class="el-icon-info"></i>拖动滑块设置进度百分比
              </div>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="附件:">
          <upload-files :files="fileList"
                        :key="key"
                        action="https://api.gradgroup.cn/ftp/ftp/upload"
                        :limit="5"
                        @fun="dealFiles2"
                        @delfun="deleteFiles2"
                        :multiple="false"
                        :IsDisabled="operation1"
                        :IsDel="operation1"></upload-files>
        </el-form-item>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button type="goon"
                   @click.native="checkSubmit">保存</el-button>
      </div>
    </el-dialog>
    <!--编辑工作进度界面-->
    <el-dialog :visible.sync="editProgressVisible"
               v-model="editProgressVisible"
               :close-on-press-escape="false"
               :close-on-click-modal="false"
               style="width: 80%;"
               center>
      <template slot="title">
        <span style="color:#FFFFFF;font-size: 16px;">编辑工作进度</span>
      </template>
      <el-form :model="editProgressForm">
        <el-form-item label="进度内容:"
                      prop="ProgressDescription">
          <el-input v-model="editProgressForm.ProgressDescription"
                    type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="具体进度:">
          <el-progress :percentage="pValue2"
                       :color="customColor"></el-progress>
          <div>
            <!-- <el-button icon="el-icon-plus" @click="increase" style="float:right;"></el-button> -->
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button type="goon"
                   @click.native="editcheckSubmit">完成</el-button>
      </div>
    </el-dialog>

    <el-dialog title="选择人员"
               :visible.sync="addUserVisible"
               v-model="addUserVisible"
               width="75%"
               center
               append-to-body>
      <UserChoose :data="choosedusers"
                  :all="true"
                  :single="false"
                  @callback="chooseUserCallBack"></UserChoose>
    </el-dialog>

    <el-dialog title="选择人员"
               :visible.sync="personUserVisible"
               v-model="personUserVisible"
               width="75%"
               center
               append-to-body>
      <UserChoose :data="choosedusers"
                  :all="true"
                  :single="false"
                  @callback="chooseUserCallBack"></UserChoose>
    </el-dialog>

    <zhibiaoconfig v-if="zhibiaopeizhi" @closeFunction="checkzhibiaoclose"  ></zhibiaoconfig>

    <zhibiaoconfigadd v-if="zhibiaopeizhiadd" @closeFunction="checkzhibiaoaddclose" :type="0" ></zhibiaoconfigadd>

  </section>
</template>
<script>
import util from '../../../util/date';
import { Loading } from 'element-ui'
import { QueryPageTemplateByUserCode,QueryTemplateDetailByTemplateId,AddTaskCollect, RemoveTaskCollect, AddTaskProgressReview,QueryReviewByProgressId, GetClassifyList, GetMyUpLeader, AddTaskAndAssign, QueryTaskByUserCode, GetUserPostList, AddTask, AddTask20210524 , DeleteTaskById, UpdateTaskContent, AssignTask, AddTaskProgress, QueryTaskProgressByTaskId, UpdateTaskProgress, DeleteTaskProgress, CancelTask, QueryChildTasksByParentId, ReSubmitApproval, GetYearPlanListByUserCode, QueryConfigInfoByYearPlanId, QueryTasksById, QueryTaskExtendDataByTaskId, GetPostNodeUserViewPageByUserId } from '../../api/oa';
import taskinfo from "../../components/taskinfo.vue"
import zhibiaoconfig from "../../components/zhibiaoconfig.vue"
import zhibiaoconfigadd from "../../components/zhibiaoconfigadd.vue"
import { getButtonList } from "../../promissionRouter";
import Userchoosetool from "../../components/Userchoosetool";
import UserChoose from "../../components/UserChoose";
import UploadFiles from "../../components/UploadFiles";
import Toolbar from "../../components/Toolbar";
import editordesc from '../../components/editordesc.vue'
export default {
  components: { taskinfo, editordesc, zhibiaoconfig, zhibiaoconfigadd, Toolbar, Userchoosetool, UploadFiles, UserChoose },
  data () {
    return {
      assignDescModeVal:false,
      editDescModeVal:false,
      descModeVal: false,
      zhibiaosource:1,
      choosetip:'点击从指标库中选择',
      zhibiaoOptions: [],
      ckbreview:false,
      currentActiveId: null,
      loading: '',
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      pageSize: 10, // 每页的数据条数
      page: 1,
      currentRow: null,
      maps: new Map(),
      currentProgressRowIndex: null,
      proRow: null,
      taskapprovestatu: null,    //任务类型审核状态
      users: [],
      tasks: [],
      checkyearplandata: [],
      progresscheck: null,      //进行完成进度验证
      upleader: null,
      isapprov: true,
      checkyearplan: false,
      WorkItemreadonly: false,
      yearplandiv: false,
      yearplanshow: true,       //年度计划选择控制
      assigncontrol: true,
      showminus: false,
      showplus: true,
      istaskpro: true,
      zhibiaopeizhi:false,
      zhibiaopeizhiadd:false,
      pValue1: null,   //进度条数值
      pValue2: null,   //进度条数值
      customColor: '#6f7ad3',   //进度条颜色
      needName: null,
      approvalName:'',
      dutyneedname: '',
      dutypeo: 'dutypeo',
      mytext:'选择他人',
      assignpeo: 'assignpeo',
      showleader: false,
      showinstruct: false,
      //指令人
      orderneedname: null,
      orderpeo: 'orderpeo',
      shenherenyuan:'shenherenyuan',
      showCreated: true,
      showAssign: false,
      showSubmit: true,    //提交按钮是否显示
      showNext: false,     //下一步按钮是否显示
      singleAss: false,     //是否单独指派
      showYearPlanTask: false,     //年度计划任务显示
      searchVal: "",
      buttonListmsg: 'taskmanage',
      filters: {
        name: ''
      },
      pickerOptions0: {
        disabledDate (time) {
          let curDate = (new Date()).getTime();
          let one = 1 * 24 * 3600 * 1000;
          let after = 360 * 24 * 3600 * 1000;
          let oneday = curDate - one;
          return time.getTime() > Date.now() + after || time.getTime() < oneday;;
        }
      },
      // classifyname: null,
      classifyId: null,
      field0: null,
      field1: null,
      inputpla: null,
      yearplandata: [],
      inputMaxLength: null,
      MaxLength: null,
      buttonList: [],
      formitemList: [],               //动态表单内容
      YearPlanTaskList: [],          //年度计划任务类型
      CommonTaskClassifyList: [],    //任务类型
      addFormVisible: false,       //新增界面初始化
      addAssignFormVisible: false,  //新增并指派界面初始化
      editFormVisible: false,       //编辑界面初始化
      checkVisible: false,          //查看界面初始化
      addProgressVisible: false,    //新增进度界面初始化
      editProgressVisible: false,   //编辑进度界面初始化
      addUserVisible: false,
      choosedusers: [],
      selectYearplanDisabled:false,
      addFormRules: {
        CommonTaskClassify: [
          { required: true, message: '请选择任务类型', trigger: 'blur' }
        ],
        WorkItem: [
          { required: true, message: '请填写工作项目', trigger: 'blur' }
        ],
        PlanComplateTime: [
          { required: true, message: '请选择计划完成时间', trigger: 'blur' }
        ],
        WorkDescription: [
          { required: true, message: '请输入工作描述', trigger: 'blur' }
        ]
      },
      editFormRules: {
        CommonTaskClassify: [
          { required: true, message: '请选择任务类型', trigger: 'blur' }
        ],
        WorkItem: [
          { required: true, message: '请填写工作项目', trigger: 'blur' }
        ],
        WorkDescription: [
          { required: true, message: '请输入工作描述', trigger: 'blur' }
        ]
      },
      tabledata: {
        ConfigFieldId: null,
        Data: null
      },
      simpleWorkDescription:null,
      addForm: {                   //新增表单初始化
        CommonTaskClassify: 31,
        WorkItem: null,
        WorkDescription: null,
        PlanComplateTime: null,
        Remark: null,
        IsAssign: false,
        IsNeedApproval: false,
        CreatedBy: null,
        CreatedId: null,
        AssignName: null,
        AssignCode: null,
        PersonOfDuty: null,
        PersonOfDutyCode: null,
        //指令人
        InstructUserName: null,
        InstructUserCode: null,
        WorkItem2: null,
        WorkDescription2: null,
        TaskExtendData: [],
        Tag: null,
        TagType:0,
        DefaultComplated: false
      },
      editForm: {              //编辑表单初始化
        Id: null,
        CommonTaskClassify: null,
        WorkItem: null,
        WorkDescription: null,
        PlanComplateTime: null,
        Remark: null,
      },
      checkForm: {            //查看表单初始化
        Id: null,
        CommonTaskClassify: null,
        WorkItem: null,
        WorkDescription: null,
        PlanComplateTime: null,
        Remark: null,
        IsNeedApproval: null
      },
      addProgressForm: {            //进度新增表单初始化
        ProgressDescription: null,
        CreatedBy: null,
        CreatedId: null

      },
      editProgressForm: {          //进度编辑表单初始化
        Id: null,
        ProgressDescription: null,
        CreatedBy: null,
        CreatedId: null
      },
      addProgressFormRules: {
        ProgressDescription: [
          { required: true, message: '请输入进度描述', trigger: 'blur' }
        ]
      },
      fromaYearRules: {
        undefined: [
          { required: true, message: '该项为必填项', trigger: 'blur' }
        ]
      },
      iscomplated: true,
      fileList: [],
      operation1: true,
      key: 0,
      PersonOfDutyArr:[],
      personUserVisible:false,
      selectPeopleDisabled:false,
      popVisiable:false,
      content:'',
      mapReview:new Map(),
      collectBtn:false,
      collectRowId:'',
      mapLevel:new Map(),
      visiableZhibiao:true,
      typeList: [
        { label: '数字', value: 1 },
        { label: '文本', value: 2 },
        // { label: '日期', value: 3 },
        // { label: '时间', value: 4 }
      ],
      lookdetail:[]
    }
  },
  watch: {
    contentDesc(){

    },
    contentEditDesc(){

    },
    contentAssignDesc(){

    },
  },
  methods: {
    handleTaskClassifyChange(val){
      // console.log(val);
      this.addForm.CommonTaskClassify=val;
      this.classifyId = val;
    },
    handleAddFormOpened(){
      console.log(this.addForm.CommonTaskClassify);
      // this.$forceUpdate();
    },
    catchDataDesc(c){
      this.contentDesc = c
      this.addForm.WorkDescription = c;
    },
    catchEditDataDesc(c){
      this.contentEditDesc = c
      this.editForm.WorkDescription = c;
    },
    catchAssignDataDesc(c){
      this.contentAssignDesc = c
      this.addForm.WorkDescription2 = c;
    },
    handleChooseZhibiao()
    {
      // alert();
      if(this.zhibiaosource==1)
      {
        this.handleZhibiao();
      }else if(this.zhibiaosource==2){
        this.handleAddZhibiaoConfig();
      }
    },
    handleChangeZhibiaoSource()
    {
      if(this.zhibiaosource==1)
      {
        this.choosetip='点击从指标库中选择';
      }else if(this.zhibiaosource==2){
        this.choosetip='点击新建指标';
      }
      this.addForm.TemplateId=null;
      this.addForm.TemplateName='';
    },
    handleAddZhibiaoConfig()
    {
      this.zhibiaopeizhiadd = true;
    },
    formatType (value) {
      for(var a=0;a<this.typeList.length;a++)
      {
        if(this.typeList[a].value==value)
        {
          return this.typeList[a].label;
        }
      }
      return ''
    },
    handleLookTemplateDetail(templateId)
    {
      let para = { templateId: templateId };
      QueryTemplateDetailByTemplateId(para).then((res) => {
        // console.log(res)
        this.lookdetail = res.data.response;
      });
    },
    handleZhibiaoSelectChange()
    {
      this.$forceUpdate();
    },
    handleDefaultComplatedSwitchChange(val)
    {
      if(val)
      {
        this.selectPeopleDisabled = true;
        var user = JSON.parse(window.localStorage.user);
        this.dutyneedname = user.name;
        this.addForm.PersonOfDuty = user.name;
        this.addForm.PersonOfDutyCode = user.sub;
        this.visiableZhibiao = false;
        this.addForm.TemplateId=null;
      }else{
        this.selectPeopleDisabled = false;
        this.visiableZhibiao = true;
      }
    },
    handleZhibiao()
    {
      this.zhibiaopeizhi = true;
    },
    needapprovalChange(val)
    {
      if(val)
      {
        if(!this.addForm.ApprovalUserCode)
        {
          var user = JSON.parse(window.localStorage.user);
          this.addForm.ApprovalUserCode = user.sub;
          this.addForm.ApprovalUserName = user.name;
        }
      }else{
          this.addForm.ApprovalUserCode = null;
          this.addForm.ApprovalUserName = null;
      }
    },
    mycellStyle(row,column,rowIndex,columnIndex)
    {
      // return 'background:red'
      // console.log(row);
      if(row.row.Level===1)
      {
        return 'background:#fff'
      }
      else if(row.row.Level===2)
      {
        return 'background:#e9f3ff'
      }
      else if(row.row.Level===3)
      {
        return 'background:#d4e9ff';
      }
      else if(row.row.Level===4)
      {
        return 'background:#bfdeff';
      }
      else if(row.row.Level===5)
      {
        return 'background:#aad2ff';
      }
      else if(row.row.Level===6)
      {
        return 'background:#96c8ff';
      }
      else if(row.row.Level===7)
      {
        return 'background:#81bdff';
      }
      else if(row.row.Level===8)
      {
        return 'background:#6eb3ff';
      }
      else if(row.row.Level===9)
      {
        return 'background:#5ba8ff';
      }
      else if(row.row.Level===10)
      {
        return 'background:#499eff';
      }
      return ''
    },
    handleCollect(row)
    {
      if(row.CollectId==null)
      {
        var para={};
        para.TaskID=row.Id;
        var user = JSON.parse(window.localStorage.user);
        para.CreatedId = user.sub;
        para.CreatedBy = user.name;
        // console.log(para)
        // return;
        AddTaskCollect(para).then((res) => {
          this.$message({
            message: '已特别关注',
            type: 'success'
          });
          // console.log(res);
          var id=res.data.response;
          row.CollectId=id;
        })
      }else{
        this.$confirm("确定要关闭对此任务的关注吗？", "提示", {}).then(() => {
          var para={};
          para.Id=row.CollectId;
          para.TaskID=row.Id;
          RemoveTaskCollect(para).then((res) => {
            this.$message({
              message: '已取消关注',
              type: 'success'
            });
            row.CollectId=null;
          })
        }).catch(()=>{});
      }
    },
    tabmouseEnter(row, column, cell, event)
    {
      if(column.property!='WorkItem')return;
      this.collectBtn=true;
      this.collectRowId=row.Id;
      // console.log(row)
    },
    tabmouseLeave(row, column, cell, event)
    {
      this.collectBtn=false;
      this.collectRowId='';
    },
    handlePersonChange(val){
      // console.log(val);
      this.addForm.PersonOfDutyCode = val;
      var name ='';
      for(var a=0;a<this.PersonOfDutyArr.length;a++)
      {
        if(this.PersonOfDutyArr[a].value==val)
        {
          name=this.PersonOfDutyArr[a].label;
          break;
        }
      }
      this.dutyneedname=name;
      this.addForm.PersonOfDuty=name;
      var user = JSON.parse(window.localStorage.user);
      if(user.sub==val)
      {
        this.selectYearplanDisabled=false;
      }else{
        this.selectYearplanDisabled=true;
        this.addForm.YearPlanTask=null;
      }
    },
    loadMyNodeUser()
    {
      let self=this;
      var user = JSON.parse(window.localStorage.user);
      GetPostNodeUserViewPageByUserId({userId: user.sub, pageNum:1,pageSize:1000}).then(res=>{
        // console.log(res);
        var usersdata = res.data.data;
        // console.log(usersdata);
        if(usersdata==null)
        {
          self.selectPeopleDisabled=true;
        }else{
          self.PersonOfDutyArr.push({
            value:user.sub,
            label:user.name
          });
          for(var a=0;a<usersdata.list.length;a++)
          {
            // console.log(usersdata[a]);
            self.PersonOfDutyArr.push({
              value:usersdata.list[a].id,
              label:usersdata.list[a].name
            });
          }
        }
      });
    },
    handleChange(val)
    {
      // console.log(val)
      if(val.length>0)
      {
        var progressId = val[0];
        this.loadReview(progressId);
      }
    },
    catchData(c)
    {
      // console.log(c)
      this.content=c;
    },
    handlePopoShow()
    {
      
    },
    handlePopoReplyShow()
    {

    },
    handleReplyReview(progress,rid)
    {
      // console.log(progress);
      // console.log(rid);
      let self=this;
      var progressId = progress.Id;
      var user = JSON.parse(window.localStorage.user);
      var para={};
      para.TaskID=this.checkForm.Id;
      para.ProgressID=progressId;
      para.ReplayID = rid;
      para.ReviewContent = self.content;
      para.CreatedId = user.sub;
      para.CreatedBy = user.name;
      // console.log(para);

      AddTaskProgressReview(para).then((res) => {
        // console.log(res);
        if (res.data.success) {
          this.$message({
            message: res.data.msg,
            type: 'success'
          });
          for(var a=0;a<self.tasks.length;a++)
          {
            if(this.tasks[a].Id==progressId)
            {
              this.tasks[a].ReviewCount=this.tasks[a].ReviewCount+1;
              break;
            }
          }
          this.loadReview(progressId);
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          });
        }
      })
    },
    handleSendReview(progress)
    {
      let self=this;
      // this.$confirm('评论即将发表，确定吗？', '提示', {}).then(() => 
      {
        var progressId = progress.Id;
        var user = JSON.parse(window.localStorage.user);
        var para={};
        para.TaskID=this.checkForm.Id;
        para.ProgressID=progressId;
        para.ReplayID = null;
        para.ReviewContent = self.content;
        para.CreatedId = user.sub;
        para.CreatedBy = user.name;
        // console.log(para);

        AddTaskProgressReview(para).then((res) => {
          // console.log(res);
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: 'success'
            });
            for(var a=0;a<self.tasks.length;a++)
            {
              if(this.tasks[a].Id==progressId)
              {
                this.tasks[a].ReviewCount=this.tasks[a].ReviewCount+1;
                break;
              }
            }
            this.loadReview(progressId);
          } else {
            this.$message({
              message: res.data.msg,
              type: 'error'
            });
          }
        })
      }
      // )
    },
    loadReview(progressid)
    {
      let self=this;
      // console.log(progressid)
      let para = { progressId: progressid };
      QueryReviewByProgressId(para).then((res) => {
        // console.log(res)
        var data = res.data.response;
        // console.log(data);
        self.mapReview.set(progressid,data);
        // console.log(self.mapReview);
        this.$forceUpdate()
        // for(var a=0;a<data.length;a++)
        // {
        //   self.mapReview.set(progressid,data);
        // }
      });
    },
    getReplyReview(arr,id)
    {
      // console.log(arr);
      // console.log(id);
      var o ={
        CreatedBy:'',
        CreatedId:'',
        CreatedTime:'',
        ReviewContent:''
      };
      for(var i=0;i<arr.length;i++)
      {
        if(arr[i].Id==id)
        {
          o = arr[i];
          break;
        }
      }
      return o;
    },
    handleReviewChange(val)
    {
      if(val)
      {
        this.iscomplated=false;
        this.addForm.DefaultComplated=false;
        this.selectPeopleDisabled = true;
        var user = JSON.parse(window.localStorage.user);
        this.dutyneedname = user.name;
        this.addForm.PersonOfDuty = user.name;
        this.addForm.PersonOfDutyCode = user.sub;
        // window.localStorage.setItem('ckbreview',true);
      }
      else
      {
        this.iscomplated=true;
        this.addForm.DefaultComplated=false;
        this.selectPeopleDisabled = false;
        // window.localStorage.setItem('ckbreview',false);
      }
      //console.log(val);
    },
    review(){
      this.popVisiable=true;
    },
    handleProcessSelectChange (taskid, index) {
      this.currentActiveId = taskid;
      this.currentProgressRowIndex = index;
    },
    showTaskFile (file) {
      window.open(file, "_blank");
    },
    handleChooseAssUser () {
      this.addUserVisible = true;
    },
    handleChoosePersonUser()
    {
      this.personUserVisible = true;
    },
    //监听子组件事件 选择人员回调
    chooseUserCallBack (data) {
      // console.log(data);
      var newdata = data.map(function (item) {
        return item["Id"];
      });
      var namenewdata = data.map(function (item) {
        return item["UserNameClaim"];
      });
      // console.log(newdata);

      var str = "";
      for (var i = 0; i < namenewdata.length; i++) {
        str += namenewdata[i] + ",";
      }
      if (str.length > 0) {
        str = str.substr(0, str.length - 1);
      }

      var idstr = "";
      for (var i = 0; i < newdata.length; i++) {
        idstr += newdata[i] + ",";
      }
      if (idstr.length > 0) {
        idstr = idstr.substr(0, idstr.length - 1);
      }
      this.needName = str;
      this.addForm.AssignCode = idstr;
      this.addForm.AssignName = str;
      this.addUserVisible = false;
    },
    handleProcessInput (val) {
      if (this.pValue2 < this.pValue1) {
        this.pValue2 = this.pValue1;
      }
      if (this.progresscheck == true) {
        if (this.pValue2 >= 100) {
          if (this.checkForm.IsNeedApproval == false) {
            this.$message({
              message: '任务已完成，请提交！',
              type: 'success'
            });
          } else {
            this.$message({
              message: '任务已完成，请提交审核！',
              type: 'warning'
            });
          }
          this.pValue2 = 100;
        }
      } else {
        if (this.pValue2 > 95) {
          this.$message({
            message: "当前任务被指派人并未都完成被指派任务,故进度最大只能添加至95%！",
            type: "warning",
            duration: 10000
          });
          this.pValue2 = 95;
        }
      }
    },
    dealFiles (data) {
      // console.log(data.backData);
      if (data.backData.length > 0) {
        this.addForm.FileArry = data.backData;
        var filename = "";
        var fileaddress = "";
        data.backData.forEach(element => {
          filename += element.FileName;
          filename += ",";
          fileaddress += element.URL;
          fileaddress += ",";
        });
        this.addForm.FileName = filename;
        this.addForm.FileAddress = fileaddress;
      }
    },
    dealFiles2 (data) {
      // console.log(data.backData);
      if (data.backData.length > 0) {
        this.addProgressForm.FileArry = data.backData;
        var filename = "";
        var fileaddress = "";
        data.backData.forEach(element => {
          filename += element.FileName;
          filename += ',';
          fileaddress += element.URL;
          fileaddress += ',';
        });
        this.addProgressForm.FileName = filename;
        this.addProgressForm.FileAddress = fileaddress;
      }
    },
    deleteFiles (data) {
      if (data.backData.length >= 0) {
        this.addForm.FileArry = "";
        var filename = "";
        var fileaddress = "";
        data.backData.forEach(element => {
          filename += element.FileName;
          filename += ",";
          fileaddress += element.URL;
          fileaddress += ",";
        });
        this.addForm.FileName = filename;
        this.addForm.FileAddress = fileaddress;
      }
    },
    deleteFiles2 (data) {
      if (data.backData.length >= 0) {
        this.addProgressForm.FileArry = "";
        var filename = "";
        var fileaddress = "";
        data.backData.forEach(element => {
          filename += element.FileName;
          filename += ",";
          fileaddress += element.URL;
          fileaddress += ",";
        });
        this.addProgressForm.FileName = filename;
        this.addProgressForm.FileAddress = fileaddress;
      }
    },
    selectCurrentRow (val) {
      this.currentRow = val;
    },
    selectCurrentZhibiaoRow (val)
    {
      this.currentZhibiaoRow = val;
    },
    taskProgressRowClassName ({ row, rowIndex }) {
      row.index = rowIndex;
    },
    onRowClick (row, event, column) {
      //行点击消除new标记
      this.currentProgressRowIndex = row.index;
    },
    selectProRow (val) {
      this.proRow = val;
    },
    callFunction (item) {
      this.filters = {
        name: item.search
      };
      this[item.Func].apply(this, item);
    },
    formatPlanComplateTime: function (row, colume) {
      return (!row.PlanComplateTime || row.PlanComplateTime == '') ? '' : util.formatDate.format(new Date(row.PlanComplateTime), 'yyyy-MM-dd');
    },
    formatTipCreateTime: function (row, column) {
      return (!row.CreateTime || row.CreateTime == '') ? '' : util.formatDate.format(new Date(row.CreateTime), 'yyyy-MM-dd hh:mm');
    },
    TStatus: function (row, column) {
      return row.TaskStatus == '新建任务' ? '否' : row.TaskStatus == '已指派' ? '是' : '未知';
    },
    // formatSource: function (row,column) {
    //     return row.ParentId != null ? '被指派' : row.ParentId == null ? '新建' : '未知';
    // },
    formatCreateTime: function (row, column) {
      return (!row.CreateTime || row.CreateTime == '') ? '' : util.formatDate.dateformat(new Date(row.CreateTime));
    },
    formatCreatedOn: function (row, colume) {
      return (!row.CreatedOn || row.CreatedOn == '') ? '' : util.formatDate.format(new Date(row.CreatedOn), 'yyyy-MM-dd hh:mm');
    },
    formatTypeOld (row) {
      if (row.FieldMustInput == 1) {
        this.inputpla = "此项为必填";
      } else {
        this.inputpla = "此项为非必填";
      }
      if (row.FieldType != 2) {
        return "string";
      } else if (row.FieldType == 2) {
        return "number";
      }
    },
    controlInputLength: function (row) {

      if (row.FieldValue != undefined) {
        if (row.FieldType == 1) {
          this.inputMaxLength = row.FieldLength;
        } else if (row.FieldType == 2) {
          this.MaxLength = 1000000000;
          if (this.checkRate(row.FieldValue) == false) {
            this.$message({
              message: '请输入数字类型。',
              type: 'warning',
              duration: 5000
            });
            return;
          }

        }
      }

    },
    checkRate (input) {
      var re = /^[0-9]+.?[0-9]*/;//判断字符串是否为数字//判断正整数/[1−9]+[0−9]∗]∗/ 
      if (!re.test(input)) {
        return false;
      }
    },
    formatShow (show) {
      if (show.row.FieldMustInput == 1) {
        return true;
      } else if (show.row.FieldMustInput == 2) {
        return false;
      }
    },
    FieldTypeFormat: function ({ cellValue, row, column }) {
      if (row.FieldType == 1) {
        return "文本";
      } else if (row.FieldType == 2) {
        return "数字";
      } else if (row.FieldType == 3) {
        return "时间";
      } else {
        return "日期";
      }
    },
    FieldMustInputFormat: function ({ cellValue, row, column }) {
      if (row.FieldMustInput == 1) {
        return "是";
      } else {
        return "否";
      }
    },
    formatProgress: function (row, col) {
      return row.TaskProgressValue + "%";
    },
    formatCommonTaskClassifyText: function (row, colume) {
      if (row.TaskClassify == 1) {
        return row.CommonTaskClassifyText;
      } else {
        if (row.TaskClassify == 2) {
          return "跨部门协调";
        }
        else if (row.TaskClassify == 3) {
          return "例会项目";
        } else if (row.TaskClassify == 4) {
          return "年度计划";
        } else if (row.TaskClassify == 5) {
          return "专项会议";
        }
        else {
          return "其他";
        }
      }
    },
    tableRowClassName ({ row, rowIndex }) {
      if (row.TaskStatus == "被指派") {
        return 'success-row';
      } else {
        if (rowIndex % 2 !== 0) {
          return 'warning-row';
        }
        return '';
      }
    },
    selectchange (val) {
      //this.iscomplated = true;
      // this.assigncontrol = false;
      // let [lable, vaue, isNeedUpLeaderApproval] = val.split('|') // es6 数组解构赋值
      // this.classifyname = [lable, vaue, isNeedUpLeaderApproval][0];
      // if (
      //   [lable, vaue, isNeedUpLeaderApproval][1] == "日常工作" || 
      //   [lable, vaue, isNeedUpLeaderApproval][1] == "月度计划" ||
      //   [lable, vaue, isNeedUpLeaderApproval][1] == "临时工作" || 
      //   [lable, vaue, isNeedUpLeaderApproval][1] == "流程批复" ||
      //   [lable, vaue, isNeedUpLeaderApproval][1] == "会议项目" 
      // ) {
      //   this.showYearPlanTask = true;
      //   this.showleader = false;
      //   var user = JSON.parse(window.localStorage.user);
      //   let para = { userCode: user.sub };
      //   GetYearPlanListByUserCode(para).then((res) => {
      //     this.YearPlanTaskList = res.data.response;
      //   });
      // }
      // else if ([lable, vaue, isNeedUpLeaderApproval][1] == "领导安排") {
      //   this.showleader = true;
      //   this.showYearPlanTask = false;
      //   this.WorkItemreadonly = false;
      //   this.addForm.WorkItem = null;
      //   this.addForm.YearPlanTask = null;
      //   this.yearplanshow = true;
      //   this.assigncontrol = true;
      //   this.yearplandiv = false;
      // }
      // else {
      //   this.showleader = false;
      //   this.WorkItemreadonly = false;
      //   this.addForm.WorkItem = null;
      //   this.addForm.YearPlanTask = null;
      //   this.showYearPlanTask = false;
      //   this.yearplanshow = true;
      //   this.assigncontrol = true;
      //   this.yearplandiv = false;
      // }
      // if ([lable, vaue, isNeedUpLeaderApproval][2] == 1) {
      //   this.$message({
      //     message: '该任务类型下的任务需上级领导' + this.upleader + '审核成功后方可执行！',
      //     type: 'warning',
      //     duration: 15000
      //   });
      //   this.assigncontrol = false;
      // } else {
      //   this.assigncontrol = true;
      // }
    },
    handleCurrentChange (val) {
      this.page = val;
      this.getNewTask();
      // this.page = 1;
    },
    handleCurrentChangeZb (val) {
      this.pagezb = val;
      this.queryTemplateList();
    },
    rowformat(element)
    {
      var has = this.mapLevel.has(element.Tag)
      var parentLevel = 0;
      if(has)
      {
        parentLevel = this.mapLevel.get(element.Tag);
      }
      else
      {
        parentLevel = this.mapLevel.get(element.ParentId);
      }
      var level = parentLevel+1;
      if(level==2)
      {
        element.MarginLeft = 30;
      }else if(level == 3){
        element.MarginLeft = 60;
      }else if(level == 4){
        element.MarginLeft = 90;
      }else if(level == 5){
        element.MarginLeft = 120;
      }else if(level == 6){
        element.MarginLeft = 150;
      }else if(level == 7){
        element.MarginLeft = 180;
      }else if(level == 8){
        element.MarginLeft = 210;
      }else if(level == 9){
        element.MarginLeft = 240;
      }else if(level == 10){
        element.MarginLeft = 270;
      }
      element.Level = level;
      this.mapLevel.set(element.Id,level);
      return element;
    },
    load (tree, treeNode, resolve) {
      // 将当前选中节点数据存储到map中
      this.maps.set(tree.Id, { tree, treeNode, resolve })
      let para = {
        taskId: tree.Id,
      };
      QueryChildTasksByParentId(para).then((res) => {
        var myarr = new Array();
        var number = 0;
        res.data.response.forEach(element => {
            element.Number = ++number;
            element = this.rowformat(element);
            myarr.push(element)
        })
        resolve(myarr);
        // resolve(res.data.response)
      });

    },
    // 重新触发树形表格的loadTree函数
    refreshLoadTree (parentId) {
      // 根据父级id取出对应节点数据
      const { tree, treeNode, resolve } = this.maps.get(parentId)
      this.$set(this.$refs.multipleTable.store.states.lazyTreeNodeMap, parentId, [])
      if (tree) {
        this.load(tree, treeNode, resolve)
      }
    },
    //获取上级级领导
    getLeader () {
      // var user = JSON.parse(window.localStorage.user);
      // let para = { userCode: user.sub };
      // GetMyUpLeader(para).then((res) => {
      //   this.upleader = res.data.response;
      // });
    },
    getNewTask () {
      var user = JSON.parse(window.localStorage.user);
      let para = {
        userCode: user.sub,
        pageIndex: this.page,
        pageSize: this.pageSize,
        queryKey: this.filters.name
      };
      QueryTaskByUserCode(para).then((res) => {
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
        var arr=new Array();
        var number = (this.page-1)*this.pageSize;
        res.data.response.data.forEach(element => {
          // console.log(element);
          number++;
          element.Number=number;
          element.MarginLeft=3;
          element.Level = 1;
          this.mapLevel.set(element.Id,1);
          arr.push(element)
        });
        this.users = arr;
        // this.users = res.data.response.data;
      });
    },
    //下一步
    nextStep () {
      if (this.addForm.WorkItem != null && this.addForm.WorkDescription != null && this.addForm.PlanComplateTime != null) {
        this.addAssignFormVisible = true;
        this.addForm.WorkItem2 = this.addForm.WorkItem;
        this.addForm.WorkDescription2 = this.addForm.WorkDescription;
      } else {
        this.tipMessage();
      }

    },
    closeassign () {
      this.addForm.AssignName = null;
      this.addForm.WorkItem2 = null;
      this.addForm.WorkDescription2 = null;
    },
    //新增
    handleAdd () {
      this.showNext = false;
      this.showSubmit = true;
      this.needName = null;
      this.addFormVisible = true;
      this.showYearPlanTask = false;
      this.yearplandata = [];
      this.yearplandiv = false;
      // this.iscomplated = false;
      this.WorkItemreadonly = false;
      var user = JSON.parse(window.localStorage.user);
      this.dutyneedname = user.name;
      this.addForm.PersonOfDuty = user.name;
      this.addForm.PersonOfDutyCode = user.sub;
      this.addForm.PlanComplateTime = util.formatDate.format(new Date(), 'yyyy-MM-dd');
      this.addForm.Tag=null;
      this.addForm.TagType=0;
      GetClassifyList().then((res) => {
        this.CommonTaskClassifyList = res.data.response;
        //console.log(this.CommonTaskClassifyList);
        this.addForm.CommonTaskClassify = this.CommonTaskClassifyList[0].Id;
        // this.classifyname = this.CommonTaskClassifyList[0].Id;
        this.classifyId = this.CommonTaskClassifyList[0].Id;
        
        //this.addForm.CommonTaskClassify = "1";
        //this.iscomplated = true;
        this.assigncontrol = false;
        this.showYearPlanTask = true;
        this.showleader = false;
        let para = { userCode: user.sub };
        GetYearPlanListByUserCode(para).then((res) => {
          this.YearPlanTaskList = res.data.response;
        });
      });


      // var ckbreviewValue = window.localStorage.ckbreview;
      // //console.log('ckbreviewValue:'+ckbreviewValue);

      // if(ckbreviewValue == undefined)
      // {
      //   this.ckbreview=false;
      //   this.iscomplated=true;
      // }
      // else
      // {
      //   if(ckbreviewValue=='true')
      //   {
      //     this.ckbreview=true;
      //   }else{
      //     this.ckbreview=false;
      //     this.iscomplated=true;
      //   }
      // }
      this.loadZhibiaoToOptions();
    },
    async loadZhibiaoOptions(){
      this.zhibiaoOptions=[];
      var user = JSON.parse(window.localStorage.user);
      await QueryPageTemplateByUserCode({userCode:user.sub,ispublic:false,istemp:true}).then((res) => {
        // console.log(res);
        if(res.status==200)
        {
          var data = res.data.response.data;
          data.forEach(element => {
            var option={
              value:element.Id,
              label:element.TemplateName
            }
            this.zhibiaoOptions.push(option);
          });
        }
      });
    },
    async loadZhibiaoToOptions()
    {
      this.zhibiaoOptions=[];
      var user = JSON.parse(window.localStorage.user);
      var arr1=new Array();
      await QueryPageTemplateByUserCode({userCode:user.sub,ispublic:false}).then((res) => {
        // console.log(res);
        if(res.status==200)
        {
          var data = res.data.response.data;
          if(data.length>0)
          {
            data.forEach(element => {
              var option={
                value:element.Id,
                label:'['+element.CreatedDpt+']'+element.TemplateName
              }
              arr1.push(option);
            });
          }
        }
        this.zhibiaoOptions.push({
          label:'私有',
          value:'1',
          options:arr1
        })

        var arr2=new Array();
        QueryPageTemplateByUserCode({ispublic:true}).then((res) => {
          // console.log(res);
          if(res.status==200)
          {
            var data = res.data.response.data;
            if(data.length>0)
            {
              data.forEach(element => {
                var option={
                  value:element.Id,
                  label:'['+element.CreatedDpt+']'+element.TemplateName
                }
                arr2.push(option);
              });
            }
          }
          this.zhibiaoOptions.push({
            label:'公共',
            value:'2',
            options:arr2
          })
        });
      });
    },
    handleChangeWorkdesc(){
      let self = this;
      self.addForm.WorkDescription = self.simpleWorkDescription;
    },
    handleChangeDescMode(){
      let self = this;
      if(self.descModeVal){
        self.addForm.WorkDescription = self.contentDesc;
      }else{
        self.addForm.WorkDescription = self.simpleWorkDescription;
      }
    },
    addSubmit()
    {
      let self=this;
      console.log(self.descModeVal);
      // console.log(self.contentDesc);
      if(self.descModeVal){
        self.addForm.WorkDescription = self.contentDesc;
      }else{
        self.addForm.WorkDescription = self.simpleWorkDescription;
      }
      // console.log(this.addForm.WorkDescription);
      // return;

      console.log(self.addForm.WorkItem);
      console.log(self.addForm.WorkDescription);
      if(self.addForm.WorkItem==null){
        this.$message({
          message: '工作项目不能为空',
          type: 'warning'
        });
        return;
      }
      else if(self.addForm.WorkDescription==null){
        this.$message({
          message: '工作描述不能为空',
          type: 'warning'
        });
        return;
      }
      else if(self.addForm.WorkDescription.trim()==''){
        this.$message({
          message: '工作描述不能为空',
          type: 'warning'
        });
        return;
      }
      this.Loadingstart();
      let para = Object.assign({}, this.addForm);

      para.NeedScore=self.ckbreview;
      if(self.ckbreview)
      {
        para.IsNeedApproval=true;
      }else{
        para.IsNeedApproval=false;
      }
      if(this.addForm.YearPlanTask==null)
      {
        para.Tag = null;
        para.TagType = 0;
      }
      para.TaskClassify=1;

      var user = JSON.parse(window.localStorage.user);
      para.CreatedId = user.sub;
      para.CreatedBy = user.name;
      // para.CommonTaskClassify = this.classifyname;
      para.CommonTaskClassify = this.classifyId;
      let para1 = Object.assign({}, this.tabledata);
      // for (var n = 0; n < this.yearplandata.length; n++) {
      //   var data = this.yearplandata[n];
      //   this.addForm.TaskExtendData.push({ ConfigFieldId: this.yearplandata[n].Id, Data: this.yearplandata[n].FieldValue });
      //   if (data.FieldMustInput == 1) {
      //     if (data.FieldValue == undefined || data.FieldValue == "") {
      //       this.$message({
      //         message: '表格必填项未填写，请填写后再提交。',
      //         type: 'warning'
      //       });
      //       this.Loadignend();
      //       return;

      //     }
      //   }
      // }
      // window.localStorage.setItem('ckbreview',self.ckbreview);
      if(self.ckbreview)
      {
        AddTask20210524(para).then((res) => {
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: 'success'
            });
            this.Loadignend();
            this.$refs['addForm'].resetFields();
            this.addFormVisible = false;
            this.addAssignFormVisible = false;
            // window.location.reload();
            this.needName = null;
            this.dutyneedname = null;
            this.getNewTask();
          } else {
            this.$message({
              message: res.data.msg,
              type: 'error'
            });
            this.Loadignend();
          }
        }).catch(er=>{
            this.Loadignend();
        });;
      }else{
        AddTask(para).then((res) => {
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: 'success'
            });
            this.Loadignend();
            this.$refs['addForm'].resetFields();
            this.addFormVisible = false;
            this.addAssignFormVisible = false;
            // window.location.reload();
            this.needName = null;
            this.dutyneedname = null;
            this.getNewTask();
          } else {
            this.$message({
              message: res.data.msg,
              type: 'error'
            });
            this.Loadignend();
          }
        }).catch(er=>{
            this.Loadignend();
        });
      }
    },
    AssignTask()
    {
      // console.log(this.addForm.WorkDescription2);
      // return;
      let para = Object.assign({}, this.addForm);
      para.Id = this.currentRow.Id;
      var user = JSON.parse(window.localStorage.user);
      para.CreatedId = user.sub;
      para.CreatedBy = user.name;
      para.CommonTaskClassify = 0;
      // console.log(para);
      // return;
      if (para.AssignName == null || para.WorkItem2 == null || para.WorkDescription2 == null || para.PlanComplateTime2 == null) {
        this.$message({
          message: '请填写完整表单',
          type: 'warning'
        });
      } else {
        this.Loadingstart();
        AssignTask(para).then((res) => {
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: 'success'
            });
            this.Loadignend();
            this.addAssignFormVisible = false;
            this.addForm.WorkDescription2 = null;
            this.addForm.AssignName = null;
            this.addForm.PlanComplateTime2 = null;
            this.needName = null;
            this.singleAss = false;
            this.$refs.multipleTable.toggleRowExpansion(this.currentRow, false);
            this.users = []
            this.getNewTask();
            this.refreshLoadTree(this.currentRow.Id);
            // window.location.reload();
            // this.getNewTask();
          } else {
            this.$message({
              message: res.data.msg,
              type: 'error'
            });
          }
        });
      }
    },
    //退回后再次提交
    readdSubmit (row) {
      this.$confirm('请尽量完善任务描述后再次提交,确定要提交吗？', '提示', {}).then(() => {
        let para = { taskId: row.Id, taskClassify: row.TaskClassify, taskParentId: row.ParentId };
        ReSubmitApproval(para).then((res) => {
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: 'success'
            });
            this.getNewTask();
          } else {
            this.$message({
              message: res.data.msg,
              type: 'error'
            });
          }
        });
      });
    },
    //编辑
    handleEdit () {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
          duration: 10000
        });
        return;
      } else if (row.Status == 4) {
        this.$message({
          message: '当前任务仍在审核中，不能编辑',
          type: 'warning',
          duration: 10000
        });
      } else if (row.ParentId != null && row.Status != 4) {
        this.$message({
          message: '当前任务为被指派任务，暂不支持编辑',
          type: 'warning',
          duration: 10000
        });
      } else if (row.TaskProgressValue != 0) {
        this.$message({
          message: '当前任务已开始进行，不能编辑',
          type: 'warning',
          duration: 10000
        });
      }
      else {
        GetClassifyList().then((res) => {
          this.CommonTaskClassifyList = res.data.response;
        });
        this.editFormVisible = true;
        this.editForm = Object.assign({}, row);
      }
    },
    editTaskOpened(){
      if(this.editForm.WorkDescription.indexOf('<p')!=-1){
        this.editDescModeVal = true;
        this.$refs.editorEditDescElem.append(this.editForm.WorkDescription);
        // this.editForm.WorkDescription = this.editForm.WorkDescription;
      }else{
        this.editDescModeVal = false;
      }
    },
    editTaskClosed(){
        this.$refs.editorEditDescElem.clean('');
    },
    assignTaskOpened(){
      if(this.addForm.WorkDescription2.indexOf('<p')!=-1){
        this.assignDescModeVal = true;
        this.$refs.editorAssignDescElem.append(this.addForm.WorkDescription2);
        // this.addForm.WorkDescription2 = this.addForm.WorkDescription2;
      }else{
        this.assignDescModeVal = false;
      }
    },
    assignTaskClosed(){
        this.$refs.editorAssignDescElem.clean('');
    },
    //编辑提交
    editSubmit () {
      // console.log(this.editForm.WorkDescription);
      // return;
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.Loadingstart();
          let para = Object.assign({}, this.editForm);
          UpdateTaskContent(para).then((res) => {
            if (res.data.success) {
              this.$message({
                message: res.data.msg,
                type: 'success'
              });
              this.Loadignend();
              this.$refs['editForm'].resetFields();
              // this.currentRow = null;
              this.editFormVisible = false;
              this.users=[];
              this.getNewTask();
            } else {
              this.$message({
                message: res.data.msg,
                type: 'error'
              });
            }
          });
        }
      });
    },
    //删除
    handleDelete () {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要删除的一行数据！",
          type: "error",
          duration: 10000
        });
        return;
      } else if (row.ParentId != null) {
        this.$message({
          message: '当前任务为子任务，不能删除',
          type: 'error',
          duration: 10000
        });
      } else {
        this.$confirm('确认删除任务吗？', '提示', {}).then(() => {
          let para = { taskId: row.Id };
          DeleteTaskById(para).then((res) => {
            if (res.data.success) {
              this.$message({
                message: res.data.msg,
                type: 'success'
              });
              // this.currentRow = null;
              this.getNewTask();
            } else {
              this.$message({
                message: res.data.msg,
                type: 'error'
              });
            }
          });
        })
      }
    },
    //指派
    handleAssign () {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要指派的一行数据！",
          type: "error",
          duration: 10000
        });
        return;
      } else if (row.Status == 4) {
        this.$message({
          message: '当前任务仍在审核中，不能指派',
          type: 'error',
          duration: 10000
        });
      }
       else {
        this.addAssignFormVisible = true;
        this.singleAss = true;
        this.addForm.WorkItem2 = row.WorkItem;
        this.addForm.WorkDescription2 = row.WorkDescription;
      }
    },
    //取消任务
    handleCancel () {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要取消的一行数据！",
          type: "error"
        });
        return;
      }
      var ucode = JSON.parse(window.localStorage.user);
      this.$confirm('确认取消任务吗？', '提示', {}).then(() => {
        let para = {
          taskId: row.Id,
          wantCancelUserCode: ucode.sub,
          wantCancelUserName: ucode.name
        };
        CancelTask(para).then((res) => {
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: 'success'
            });
            // this.currentRow = null;
            this.users=[];
            this.getNewTask();
          } else {
            this.$message({
              message: res.data.msg,
              type: 'error'
            });
          }
        });
      });
      //    }
    },
    //责任人回调
    dutycallFunction (newdata) {
      this.dutyneedname = null;
      this.addForm.PersonOfDutyCode = newdata[0];
      let para = { userId: newdata[0] };
      GetUserPostList(para).then((res) => {
        for (var i = 0; i < res.data.data.length; i++) {
          this.addForm.PersonOfDuty = res.data.data[0].name;
          this.dutyneedname = this.addForm.PersonOfDuty;
        }
      });
    },
    //指派人回调
    assigncallFunction (newdata) {
      this.needName = null;
      this.addForm.AssignCode = newdata[0];
      let para = { userId: newdata[0] };
      GetUserPostList(para).then((res) => {
        for (var i = 0; i < res.data.data.length; i++) {
          this.addForm.AssignName = res.data.data[0].name;
          this.needName = this.addForm.AssignName;
        }
      });
    },
    //指令人回调
    ordercallFunction (newdata) {
      this.orderneedname = null;
      this.addForm.InstructUserCode = newdata[0];
      let para = { userId: newdata[0] };
      GetUserPostList(para).then((res) => {
        for (var i = 0; i < res.data.data.length; i++) {
          this.addForm.InstructUserName = res.data.data[0].name;
          this.orderneedname = this.addForm.InstructUserName;
        }
      });
    },
    //审核人回调
    approvalcallFunction (newdata) {
      console.log(newdata);
      this.approvalName = null;
      this.addForm.ApprovalUserCode = newdata[0];
      let para = { userId: newdata[0] };
      GetUserPostList(para).then((res) => {
        console.log(res);
        for (var i = 0; i < res.data.data.length; i++) {
          this.addForm.ApprovalUserName = res.data.data[0].name;
          this.approvalName = this.addForm.ApprovalUserName;
        }
      });
      console.log(this.addForm)
    },
    //双击查看详细信息
    checkInfo (row) {
      var ucode = JSON.parse(window.localStorage.user);
      this.checkForm = row;
      this.checkVisible = true;
      return;
      if (row.CommonTaskClassifyText == "领导安排") {
        this.showinstruct = true;
      } else {
        this.showinstruct = false;
      }
      if (row.TagType == 1) {
        this.checkyearplan = true;
        this.isapprov = false;
      } else {
        this.checkyearplan = false;
        this.isapprov = true;
      }
      if (row.PersonOfDuty != ucode.name) {
        this.showAssign = true;
        this.showCreated = false;
      } else {
        this.showAssign = false;
        this.showCreated = true;
      }
      QueryTaskExtendDataByTaskId({ taskId: row.Id }).then((res) => {
        this.checkyearplandata = res.data.response;
      })
      this.pValue1 = row.TaskProgressValue;
      this.pValue2 = row.TaskProgressValue;
      this.progresscheck = row.ChildrenProgressComplated;
      if (row.PersonOfDutyCode != ucode.sub || row.Status != 0) {
        this.istaskpro = false;
      } else {
        if (this.pValue1 < 100) {
          if (this.pValue1 == 95 && this.progresscheck == false) {
            this.istaskpro = false;
          } else {
            this.istaskpro = true;
          }
        } else {
          this.istaskpro = false;
        }
      }
      GetClassifyList().then((res) => {
        this.CommonTaskClassifyList = res.data.response;
      });
      this.checkForm = Object.assign({}, row);
      this.checkForm.Files = [];
      if (row.FileName != null) {
        var arr = row.FileName.split(",");
        var arr2 = row.FileAddress.split(",");
        var files = [];
        for (var a = 0; a < arr.length; a++) {
          var f = {};
          f.FileName = arr[a];
          f.FileAddress = arr2[a];
          files.push(f);
        }
        this.checkForm.Files = files;
      }
      // console.log(this.checkForm)
      let para = { taskId: row.Id };
      QueryTaskProgressByTaskId(para).then((res) => {
        //   this.tasks = res.data.response;
        var data = res.data.response;
        let formatData = new Array();
        for (var a = 0; a < data.length; a++) {
          var element = data[a];
          element.Files = [];
          if (element.FileName != null) {
            var arr = element.FileName.split(",");
            var arr2 = element.FileAddress.split(",");
            var files = [];
            for (var d = 0; d < arr.length; d++) {
              var f = {};
              f.FileName = arr[d];
              f.FileAddress = arr2[d];
              files.push(f);
            }
            element.Files = files;
          }
          formatData.push(element);
        }
        this.tasks = formatData;
        this.$nextTick(function () {
          var div = document.getElementsByClassName("taskmanagediv");
          if (div && 0 < div.length) {
            div[0].scrollTop = div[0].scrollHeight;
          }
        })
      });
    },
    checkclose () {
      this.checkVisible = false;
      this.pValue1 = 0;
      // this.getNewTask();
    },
    checkzhibiaoclose (row) {
      // console.log(row)
      if(row)
      {
        this.addForm.TemplateId = row.Id;
        this.addForm.TemplateName = row.TemplateName;
      }
      this.zhibiaopeizhi = false;
    },
    async checkzhibiaoaddclose (data) {
      this.zhibiaopeizhiadd = false;
      if(data.added==0)
      {
        return;
      }
      await this.loadZhibiaoOptions();
      // console.log(this.zhibiaoOptions)
      this.addForm.TemplateId=this.zhibiaoOptions[0].value;
      this.addForm.TemplateName=this.zhibiaoOptions[0].label;
      // console.log(this.addForm)
      this.$forceUpdate();

      // await this.loadZhibiaoToOptions();
      // if(this.zhibiaoOptions.length>0)
      // {
      //   if(this.zhibiaoOptions[0].options.length>0)
      //   {
      //     this.addForm.TemplateId=this.zhibiaoOptions[0].options[0].value;
      //     this.$forceUpdate();
      //   }
      // }
    },
    //进度新增
    addpro () {
      this.addProgressVisible = true;
      this.addProgressForm.FileName=null;
      this.addProgressForm.FileAddress=null;
      this.addProgressForm.ProgressDescription = null;
      this.showminus = false;
      this.showplus = true;
      //this.provalue2 = this.provalue;
    },
    addopen () {
      if (this.progresscheck == false) {
        this.$message({
          message: "当前任务被指派人并未都完成被指派任务,故进度最大只能添加至95%！",
          type: "warning",
          duration: 10000
        });
      }
    },
    //进度新增提交
    checkSubmit () {
      var user = JSON.parse(window.localStorage.user);
      let para = {
        taskID: this.checkForm.Id,
        progressDescription: this.addProgressForm.ProgressDescription,
        progressValue: this.pValue2,
        filename: this.addProgressForm.FileName,
        fileaddress: this.addProgressForm.FileAddress,
        createdId: user.sub,
        createdBy: user.name
      };
      if (this.addProgressForm.ProgressDescription == null) {
        this.$message({
          message: '请填写进度内容',
          type: 'warning'
        });
      } else {
        this.Loadingstart();
        AddTaskProgress(para).then((res) => {
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: 'success'
            });
            this.Loadignend();
            let para = { taskId: this.checkForm.Id };
            QueryTaskProgressByTaskId(para).then((res) => {
              // this.tasks = res.data.response;

              var data = res.data.response;
              let formatData = new Array();
              for (var a = 0; a < data.length; a++) {
                var element = data[a];
                element.Files = [];
                if (element.FileName != null) {
                  var arr = element.FileName.split(",");
                  var arr2 = element.FileAddress.split(",");
                  var files = [];
                  for (var d = 0; d < arr.length; d++) {
                    var f = {};
                    f.FileName = arr[d];
                    f.FileAddress = arr2[d];
                    files.push(f);
                  }
                  element.Files = files;
                }
                formatData.push(element);
              }
              this.tasks = formatData;
              this.RefreshProgress(this.tasks);
              this.$nextTick(function () {
                var div = document.getElementsByClassName("taskmanagediv");
                if (div && 0 < div.length) {
                  div[0].scrollTop = div[0].scrollHeight;
                }
              })
            });

            this.addProgressVisible = false;
            this.getNewTask();

          } else {
            this.$message({
              message: res.data.msg,
              type: 'error'
            });
          }
        });
      }
    },
    //进度编辑
    editpro () {
      let row = this.proRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error"
        });
        return;
      }
      this.editProgressVisible = true;
      this.editProgressForm = Object.assign({}, row);
    },
    //进度编辑提交
    editcheckSubmit () {
      let para = {
        id: this.editProgressForm.Id,
        taskID: this.checkForm.Id,
        progressDescription: this.editProgressForm.ProgressDescription,
        progressValue: this.pValue2,
        createdId: this.checkForm.CreatedId,
        createdBy: this.checkForm.CreatedBy
      };
      UpdateTaskProgress(para).then((res) => {
        if (res.data.success) {
          this.$message({
            message: res.data.msg,
            type: 'success'
          });
          this.editProgressVisible = false;
          let para = { taskId: this.checkForm.Id };
          QueryTaskProgressByTaskId(para).then((res) => {
            // this.tasks = res.data.response;
            var data = res.data.response;
            let formatData = new Array();
            for (var a = 0; a < data.length; a++) {
              var element = data[a];
              element.Files = [];
              if (element.FileName != null) {
                var arr = element.FileName.split(",");
                var arr2 = element.FileAddress.split(",");
                var files = [];
                for (var d = 0; d < arr.length; d++) {
                  var f = {};
                  f.FileName = arr[d];
                  f.FileAddress = arr2[d];
                  files.push(f);
                }
                element.Files = files;
              }
              formatData.push(element);
            }
            this.tasks = formatData;
            this.$nextTick(function () {
              var div = document.getElementsByClassName("taskmanagediv");
              if (div && 0 < div.length) {
                div[0].scrollTop = div[0].scrollHeight;
              }
            })
          });
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    },
    //删除选中进度
    deletepro () {
      // let row = this.proRow;
      if (!this.currentActiveId) {
        this.$message({
          message: "请选择要删除的一行数据！",
          type: "error",
          duration: 10000
        });
        return;
      }
      var length = this.tasks.length;
      if (this.currentProgressRowIndex < length - 1) {
        this.$message({
          message: "只允许删除最后一条进度",
          type: "error",
          duration: 10000
        });
        return;
      }
      this.$confirm('确认删除进度吗？', '提示', {}).then(() => {
        DeleteTaskProgress({ id: this.currentActiveId }).then((res) => {
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: 'success'
            });
            this.currentActiveId = null;
            let para = { taskId: this.checkForm.Id };
            QueryTaskProgressByTaskId(para).then((res) => {
              // this.tasks = res.data.response;
              var data = res.data.response;
              let formatData = new Array();
              for (var a = 0; a < data.length; a++) {
                var element = data[a];
                element.Files = [];
                if (element.FileName != null) {
                  var arr = element.FileName.split(",");
                  var arr2 = element.FileAddress.split(",");
                  var files = [];
                  for (var d = 0; d < arr.length; d++) {
                    var f = {};
                    f.FileName = arr[d];
                    f.FileAddress = arr2[d];
                    files.push(f);
                  }
                  element.Files = files;
                }
                formatData.push(element);
              }
              this.tasks = formatData;
              this.RefreshProgress(this.tasks);
              this.$nextTick(function () {
                var div = document.getElementsByClassName("taskmanagediv");
                if (div && 0 < div.length) {
                  div[0].scrollTop = div[0].scrollHeight;
                }
              })
            });
            this.getNewTask();
          } else {
            this.$message({
              message: res.data.msg,
              type: 'error'
            });
          }
        });
      });
    },
    //年度计划任务选择
    YearPlanchange (val) {
      if (val != '') {
        this.formitemList = [];
        // this.WorkItemreadonly = true;
        let [lable, vaue, workitem] = val.split('|') // es6 数组解构赋值
        this.addForm.Tag = [lable, vaue, workitem][1];
        this.addForm.TagType = 1;
        // this.addForm.WorkItem = [lable,vaue,workitem][2];
        let para = { yearPlanId: [lable, vaue, workitem][1] };
        QueryConfigInfoByYearPlanId(para).then((res) => {
          this.formitemList = res.data.response;
          this.yearplandata = res.data.response;
          if (this.yearplandata.length > 0) {
            this.yearplandiv = true;
          }
        });
        this.yearplanshow = false;
      } else {
        this.WorkItemreadonly = false;
        let [lable, vaue, workitem] = val.split('|') // es6 数组解构赋值
        this.addForm.Tag = [lable, vaue, workitem][1];
        this.addForm.TagType = 1;
        this.addForm.WorkItem = [lable, vaue, workitem][2];
        this.addForm.WorkDescription = null;
        this.yearplandata = [];
      }
    },
    clickAssign () {
      if (this.addForm.IsAssign == true) {
        this.showNext = true;
        this.showSubmit = false;
      } else {
        this.showSubmit = true;
        this.showNext = false;
      }
    },
    clickclose () {
      this.$refs['addForm'].resetFields();
      this.key = this.key + 1;
    },
    proclose () {
      this.addProgressVisible = false;
      this.addProgressForm.ProgressDescription = null;
      this.addProgressForm.FileName=null;
      this.addProgressForm.FileAddress=null;
      this.pValue2 = this.pValue1;
      this.key = this.key + 1;
    },
    tipMessage () {
      this.$message({
        message: '填写未完成，请继续填写',
        type: 'warning'
      });
    },
    RefreshProgress (tasksProgressRow) {
      var rowlength = tasksProgressRow.length;
      if (rowlength > 0) {
        this.pValue2 = tasksProgressRow[tasksProgressRow.length - 1].ProgressValue;
      } else {
        this.pValue2 = 0;
      }
      this.pValue1 = this.pValue2;
      if (this.pValue1 == 95 && this.progresscheck == false) {
        this.istaskpro = false;
      } else {
        this.istaskpro = true;
      }
    },
    Loadingstart () {
      this.loading = Loading.service({
        lock: true,
        text: '加载中...',
        background: 'rgba(0,0,0,0.1)'
      })
    },
    Loadignend () {
      this.loading.close();
    },
    chooseMe()
    {
      var user = JSON.parse(window.localStorage.user);
      this.approvalName = user.name;
      this.addForm.ApprovalUserCode = user.sub;
      this.addForm.ApprovalUserName = user.name;
    }
  },
  mounted () {

    this.getNewTask();
    this.getLeader();
    this.loadMyNodeUser();
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    var user = JSON.parse(window.localStorage.user);
    this.approvalName = user.name;
    // console.log(this.approvalName);
    //第一种写法，每个页面都需要写方法，但是可以做特性化处理
    // this.getButtonList(routers);

    //第二种写法，封装到 permissionRouter.js 中
    let buttons = window.localStorage.buttList ? JSON.parse(window.localStorage.buttList) : [];
    this.buttonList = getButtonList(this.$route.path, routers, buttons);
    
  },
  computed:
  {

  }
}
</script>
<style scoped>
.taskdiv,
.taskcard {
  height: calc(100vh - 220px);
}
.taskmanagediv {
  max-height: calc(100vh - 350px);
  overflow: auto;
  padding-left: 2px;
}
.active {
  background-color: #00000014;
}
.eldropdownitem,
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
</style>
<style rel="stylesheet/scss" lang="scss">
.vxe-select--panel {
  z-index: 9997 !important
}
//日期框
.vxe-input--panel.type--date, .vxe-input--panel.type--month, .vxe-input--panel.type--week, .vxe-input--panel.type--year 
{
  z-index: 9998 !important;
}
.vxe-dropdown--panel
{
  z-index: 2010 !important;
}
</style>

